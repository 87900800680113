import payload_plugin_49ve_86zFqmeWM6ABkRmj_HVPzIePrhXeW9CBz1EBww from "/opt/app-root/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_UGvIOpyxsAI_WSUOM6F0FtkUP6APAOWEdABTDhWwG9w from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_c2mPTgbfWUf3YgsLGcqKAGY24vi_jIy0NKP_nr_EjQg from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.18.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.3_@types+node@22.13_8aae55cd04cd26417491530acbf9bb98/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_g2BA3U3_0Qo6F3nA_2UY3bVqd_rYA8A8zo9Wt0HTxaw from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AEJ9So28HfRBQYcwk9NMWEn_VuFPTFQEgpAauk3475Y from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_x2Ft7jgzfhLWoKT7gpesgpECJ3h86t_JRMq2UVj8RD8 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_N_f_vRJ42snEq_eJx_IHkfAh4yU_fj_f_fJvTobu9WE from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LqpR1wimfZtlpHL7JUHetJBVlQIKNkuGshSVWFPiBz0 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_n57C_gJ4iF2SlSZf63UVISP2QG7BTacw0HdxSLeeess from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_S19zzxvy1YJv_oPe43zvRxnPPCkPUKf4os1l4xVRte0 from "/opt/app-root/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/app-root/src/.nuxt/components.plugin.mjs";
import prefetch_client_j6ahQMEyo9kioDchDeI1AsCcmpdgo_o96IkTt1XxQ1o from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_cd6a853842d176150f10f6ed2a744968/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import http_plugin_nuxt_7_b7922oM92LQpTd3jZ5k8zEsa365_vefv0icQhy0AM from "/opt/app-root/src/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.23.1_sass@1.86.0_typescript@5.8.2/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_gZ9x_rqSf5wtu2ET7P2l__NsJBcrHq_Rfs151lVwmrc from "/opt/app-root/src/.nuxt/auth.plugin.mjs";
import vuetify_no_client_hints_q_PXbe12cn9WbiwWwOqtlPSjLqewW3ZwwZm3mjkf0hg from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.18.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.3_@types+node@22.13_8aae55cd04cd26417491530acbf9bb98/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo from "/opt/app-root/src/plugins/auth.ts";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/opt/app-root/src/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_49ve_86zFqmeWM6ABkRmj_HVPzIePrhXeW9CBz1EBww,
  revive_payload_client_UGvIOpyxsAI_WSUOM6F0FtkUP6APAOWEdABTDhWwG9w,
  vuetify_icons_c2mPTgbfWUf3YgsLGcqKAGY24vi_jIy0NKP_nr_EjQg,
  unhead_g2BA3U3_0Qo6F3nA_2UY3bVqd_rYA8A8zo9Wt0HTxaw,
  router_AEJ9So28HfRBQYcwk9NMWEn_VuFPTFQEgpAauk3475Y,
  payload_client_x2Ft7jgzfhLWoKT7gpesgpECJ3h86t_JRMq2UVj8RD8,
  navigation_repaint_client_N_f_vRJ42snEq_eJx_IHkfAh4yU_fj_f_fJvTobu9WE,
  check_outdated_build_client_LqpR1wimfZtlpHL7JUHetJBVlQIKNkuGshSVWFPiBz0,
  chunk_reload_client_n57C_gJ4iF2SlSZf63UVISP2QG7BTacw0HdxSLeeess,
  plugin_vue3_S19zzxvy1YJv_oPe43zvRxnPPCkPUKf4os1l4xVRte0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_j6ahQMEyo9kioDchDeI1AsCcmpdgo_o96IkTt1XxQ1o,
  http_plugin_nuxt_7_b7922oM92LQpTd3jZ5k8zEsa365_vefv0icQhy0AM,
  auth_plugin_gZ9x_rqSf5wtu2ET7P2l__NsJBcrHq_Rfs151lVwmrc,
  vuetify_no_client_hints_q_PXbe12cn9WbiwWwOqtlPSjLqewW3ZwwZm3mjkf0hg,
  auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk
]