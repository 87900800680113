
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as authNpxcBrMRrqpSjuMum0jQZoXL8Anw_45kja0VNFdUOD2lEMeta } from "/opt/app-root/src/pages/auth.vue?macro=true";
import { default as indexkmHzTJ6CwmOD7gcPee9EO5gwYrW8owLMfuEGAgAlDRkMeta } from "/opt/app-root/src/pages/index.vue?macro=true";
import { default as login3_45twYa_45CPQfqWX2CMnSw0sRCYRaWGTjTl2W2DEhYJ4oMeta } from "/opt/app-root/src/pages/login.vue?macro=true";
import { default as changelogp31wDazGsuQ2RafdCArvFXPonpEB3cAr88z8TqkiUAYMeta } from "/opt/app-root/src/pages/changelog.vue?macro=true";
import { default as notification_45settingsdVH0Kb0QZs_45188OlsbqDuIelNUNoom9vDZr9yTvu3BUMeta } from "/opt/app-root/src/pages/notification-settings.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/app-root/src/pages/auth.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/app-root/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/app-root/src/pages/login.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/opt/app-root/src/pages/changelog.vue")
  },
  {
    name: "notification-settings",
    path: "/notification-settings",
    component: () => import("/opt/app-root/src/pages/notification-settings.vue")
  }
]